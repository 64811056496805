import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Box, Dash as DashUI, Flex, Title, Icon, Text } from 'Components/UI'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'
import { longArrowLeftGlyph, tagGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'
import { mapToTheme } from 'styled-map'

export const WrapperContainer = styled(Flex).attrs({
  px: CONTENT_PADDING_X,
})`
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  gap: 32px;
`

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: black;

  max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '736px')};

  @media screen and (max-width: 1200px) {
    max-width: ${({ fullwidth }) =>
      fullwidth ? '100%' : 'calc(100% - 400px)'};
  }

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    max-width: 100vw;
    margin: 0;
  }
`

export const Responsive = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  pb: 3,
})`
  width: 100%;

  ${Title} {
    text-align: center;
  }

  > svg {
    fill: ${themeGet('colors.font.primary')};
    width: 200px;
    height: auto;
  }
`

export const Row = styled(Flex).attrs({
  alignItems: 'flex-end',
  mb: [0, 0, '24px', '24px'],
})`
  width: 100%;
`

export const BackIcon = styled(Icon).attrs({
  glyph: longArrowLeftGlyph,
  size: 16,
  mr: 2,
})`
  fill: currentColor;
`

export const BackButton = styled(Flex).attrs({
  mr: 3,
  display: ['none', 'none', 'flex'],
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.5px;
  white-space: nowrap;
  align-items: flex-end;
`

export const Content = styled(Flex).attrs({
  mb: [0, 0, 0, 16],
  flexDirection: 'column',
})`
  color: black;
  width: 100%;
`

export const MainContent = styled(Box).attrs({
  pb: [1, 1, 4],
})`
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};

  padding: 24px;
  width: 100%;

  &:empty {
    display: none;
  }

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    width: 100%;
    border: 0;
    padding: 0;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    padding: 24px 0;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .address-holder {
    max-width: 100%;

    @media screen and (min-width: 769px) {
      max-width: 50%;
    }
  }

  .map-holder {
    flex-wrap: wrap;

    height: 125px;
    width: 100%;
    max-width: 100%;

    @media screen and (min-width: 769px) {
      height: auto;
      width: 50%;
      max-width: 50%;
    }
  }
`

export const Top = styled(Box).attrs({})`
  display: flex;
  justify-content: space-between;

  > div {
    width: auto;
  }
`

export const TopSub = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
})`
  column-gap: 16px;
`

export const StyledTitle = styled(Text).attrs({
  fontSize: [18, 3, '30px'],
})`
  color: ${themeGet('colors.black')};
  line-height: 1;
  font-weight: 700;
  text-align: left !important;
`

export const SubTitle = styled(Text).attrs(({ mt }) => ({
  alignItems: 'center',
  fontSize: 14,
  mt: mt || [3, 3, 4],
}))`
  color: ${themeGet('colors.black')};
  letter-spacing: 0.6px;
  line-height: 1.4;
`

export const MapIcon = styled(Icon).attrs({
  glyph: MapPinIcon,
  size: 16,
  mr: '3px',
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const ServiceImageWrapper = styled(Flex)`
  height: 482px;
  width: 100%;
  min-width: 215px;
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: 360px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    height: 310px;
  }
`

export const ServiceImageBg = styled(Box).attrs({})`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) center no-repeat;
  background-size: cover;
  border-radius: ${themeGet('borderRadius.1')};
`

export const ContentTitle = styled(Text).attrs({
  fontSize: 20,
})`
  color: ${themeGet('colors.black')};
  text-align: left;
  letter-spacing: 0.7px;
  font-weight: bold;
`

export const Dash = styled(DashUI).attrs({
  mt: 2,
})``

export const TagIcon = styled(Icon).attrs({
  glyph: tagGlyph,
  size: 16,
})`
  color: #000;
`

export const DescriptionText = styled(Box).attrs(() => ({
  fontSize: '14px',
}))`
  color: ${themeGet('colors.text')};
  text-transform: none;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;

  p {
    margin-top: 8px;
  }

  .merchant-about-read-more {
    color: ${({ linkcolor }) => linkcolor || mapToTheme('buttons.bg')};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const MobileStickyPanel = styled(Box).attrs({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: ['block', 'block', 'block', 'none'],
})`
  background-color: ${themeGet('colors.white')};
  box-shadow: 0px -8px 10px rgba(50, 50, 50, 0.25);

  .pricing-wrapper {
    padding: 16px 24px;
    border: none;
  }
`
